html {
  font-size: 14px;
}
html,
html[data-theme="light"] {
  /* colors */
  --text-color-danger: #e03c39;
  --text-color-primary: #348bfa;
  --text-color-dark: #000000;
  --text-color-secondary: #757575;
  --text-color-white: #ffffff;
  --text-color-warning: #f8ba00;
  /* backgrounds */
  --bg-color-danger: #fcebeb;
  --bg-color-primary: #348bfa;
  --bg-color-warning: #f8ba00;
  --bg-color-dark: #141414;
  --bg-color-white: #fff;
  --bg-color-secondary: #757575;
  --bg-color-darkgray: #e1e1e1;
  --bg-color-gray: #f6f7f8;
  /* line, borders */
  --border-color-danger: #f5cbcf;
  --border-color-secondary: #757575;
  /* --border-color-gray: #e6e6e6; */
  --border-color-gray: #ebebeb;
  --border-color-dark: #141414;
  --border-color-white: #ffffff;

  --header-height: 70px;
  --side-width: 370px;
  --main-gap: 30px;
  --mini-gap: 10px;
  --small-gap: 20px;
  --medium-gap: 40px;
  --large-gap: 80px;
}

*,
::after,
::before {
  box-sizing: border-box;
}
/* @font-face {
  font-family: "customHelvetica";
  src: url("../public/fonts/HelveticaNeueLight.woff") format("woff");
} */
body {
  font-family: "customHelvetica", "Helvetica Neue Light", "Helvetica Neue", sans-serif;

  font-size: 14px;
  font-weight: normal;
  background-color: #f5f5f5;
  line-height: 1.5;
  color: #212529;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  word-break: break-word;
}
.ctrl-noscroll,
.noscroll {
  overflow: hidden;
  /* padding-right: 30px; */
}
a {
  text-decoration: none;
  color: var(--text-color-dark);
}
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
th,
td,
label {
  font-family: "customHelvetica", "Helvetica Neue Light", "Helvetica Neue", sans-serif;
  font-weight: normal;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid var(--border-color-gray);
}
.text-color-secondary {
  color: var(--text-color-secondary);
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-20 {
  font-size: 20px;
}
.link {
  color: var(--text-color-dark);
  text-decoration: underline;
  cursor: pointer;
  /* display: flex; */
}
.link.danger {
  color: var(--text-color-danger);
}
.link.raw {
  text-decoration: none;
}
a.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
.w-100 {
  width: 100%;
}
.w-100f {
  width: 100% !important;
}
.h-100 {
  height: 100%;
}
.minh-100 {
  min-height: 100%;
}
.minvh-100 {
  min-height: 100vh;
}
.cursor-pointer {
  cursor: pointer;
}
/* container */
.container,
.container-article,
.container-pubhead,
.container-profile {
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--main-gap);
  padding-left: var(--main-gap);
}
.container {
  width: 90%;
  width: 1580px;
}
.container-profile {
  width: 780px;
}
.container-dash {
  width: 100%;
  padding-right: 30px;
}
.container-article {
  width: 720px;
}
.container-pubhead {
  width: 1580.05px;
}
.container-pubhead .row {
  margin-right: -20px;
  margin-left: -20px;
}
.container-pubhead .row > [class^="col-"] {
  padding-right: 20px;
  padding-left: 20px;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.row.small {
  margin-right: -7px;
  margin-left: -7px;
}
.row.small > .col,
.row.small > [class^="col-"] {
  padding-right: 7px;
  padding-left: 7px;
}
.row > .col,
.row > [class^="col-"] {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.col-70 {
  -ms-flex: 0 0 70%;
  flex: 0 0 70%;
  max-width: 70%;
}
.col-6,
.col-50 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.col-30 {
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  max-width: 30%;
}
.col-20 {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}
.col-25 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.col-3 {
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}
.col-9 {
  -ms-flex: 0 0 66.66666%;
  flex: 0 0 66.66666%;
  max-width: 66.66666%;
}
.col-md-4 {
  -ms-flex: 0 0 66.66666%;
  flex: 0 0 66.66666%;
  max-width: 66.66666%;
}

.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block !important;
  width: auto;
}
.d-none {
  display: none !important;
}
/* flex */
.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}
.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}
.flex-row {
  flex-direction: row !important;
  -ms-flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
  -ms-flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
  -ms-flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
  -ms-flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.flex-1 {
  flex: 1 !important;
}
.flex-basis-auto {
  flex-basis: auto;
}

.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
/* margin, padding */
.mb-0 {
  margin-bottom: 0 !important;
}
.mr-mini {
  margin-right: var(--mini-gap);
}
.ml-mini {
  margin-left: var(--mini-gap);
}
.mt-mini {
  margin-top: var(--mini-gap);
}
.mb-mini {
  margin-bottom: var(--mini-gap);
}
.mr-small {
  margin-right: var(--small-gap);
}
.ml-small {
  margin-left: var(--small-gap);
}
.mt-small {
  margin-top: var(--small-gap);
}
.mb-small {
  margin-bottom: var(--small-gap);
}
.mr-medium {
  margin-right: var(--medium-gap);
}
.ml-medium {
  margin-left: var(--medium-gap);
}
.mt-medium {
  margin-top: var(--medium-gap);
}
.mb-medium {
  margin-bottom: var(--medium-gap);
}
.mr-large {
  margin-right: var(--large-gap);
}
.ml-large {
  margin-left: var(--large-gap);
}
.mt-large {
  margin-top: var(--large-gap);
}
.mb-large {
  margin-bottom: var(--large-gap);
}

/* space */
.empty-space {
  height: var(--main-gap);
}
/* positions */
.pos-abs {
  position: absolute;
}
.pos-abs.filled {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.pos-abs.top {
  top: 0;
}
.pos-abs.bottom {
  bottom: 0;
}
.pos-abs.right {
  right: 0;
  left: auto;
}
.pos-rel {
  position: relative;
}
.pos-fix {
  position: fixed;
}
.pos-sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

/* background */
.bg-cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.bg-contain {
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-position: 100% 100%;
}
.bg-opacity {
  background-color: rgba(255, 255, 255, 0.6);
}
.bg-white {
  background-color: var(--bg-color-white);
}
/* font size */
h1 {
  font-size: 1.8rem;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 1.125rem;
}
h5 {
  font-size: 1rem;
}
h6 {
  font-size: 0.875rem;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}
.btn-dark {
  background-color: #333;
  color: #fff;
}
.btn-light {
  color: #212529;
  background-color: #e4e4e4;
}
.btn-light,
.btn-dark {
  min-width: 98px;
  text-align: center;
  border: none;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 12px;
  padding: 12px 14px;
}
.btn-icon {
  width: 46px;
  height: 46px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-icon:hover {
  background-color: #e1e1e1;
}
.btn-small {
  padding: 8px 14px;
  min-width: 75px;
}
.btn-choose-attr {
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  padding: 0;
}

/* styling */

header {
  height: var(--header-height);
  background-color: rgba(245, 245, 245, 0.8);
  backdrop-filter: blur(12px);
  position: fixed;
  z-index: 99;
  top: 0;
  width: 100%;
}
main {
  padding-top: var(--header-height);
  min-height: 100vh;
  display: flex;
}
.brand-logo {
  padding-top: 20px;
}

.header-left,
.siderbar {
  min-width: var(--side-width);
}
.header-left {
  padding-left: var(--main-gap);
}
.header-right {
  padding-right: var(--main-gap);
}
.main-wrap {
  width: 100%;
}
.main-content {
  width: 100%;
  padding-top: var(--main-gap);
  padding-right: var(--main-gap);
  padding-bottom: 80px;
}

.main-content.withoutsidebar {
  padding-left: 0;
  padding-right: 0;
}
.siderbar {
  top: 70px;
  position: sticky;
  padding: var(--main-gap);
  padding-left: 0;
  max-height: calc(100vh - 70px);
}
.sidebar-content-wrap {
  position: relative;
  height: 100%;
  width: 100%;
  display: block;
  overflow-y: auto;

  mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 17px, black 17px);
  mask-size: 100% 20000px;
  mask-position: left bottom;
  -webkit-mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 17px, black 17px);
  -webkit-mask-size: 100% 20000px;
  -webkit-mask-position: left bottom;
  transition: mask-position 0.3s, -webkit-mask-position 0.3s;
}
.sidebar-content-wrap:hover {
  -webkit-mask-position: left top;
}
.sidebar-content-wrap::-webkit-scrollbar {
  width: 8px;
  background-color: #ececec;
}

.sidebar-content-wrap::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
}

.sidebar-content-wrap::-webkit-scrollbar-thumb {
  background-color: #dbdbdb;
}

.siderbar-content {
  position: relative;
  /* position: absolute; */
  display: inline-block;
  width: 100%;
  /* height: 100%;
  overflow-y: auto; */
  padding-left: var(--main-gap);
  padding-right: var(--main-gap);
}

.nav-link {
  margin-left: 25px;
  padding: 8px 0;
  display: flex;
  align-items: center;
  position: relative;
}
.nav-link svg {
  width: 16px;
  margin-right: 5px;
}

.custom-toggle {
  border-bottom: 1px solid #e1e3e6;
}
.ct-head {
  cursor: pointer;
  text-transform: uppercase;
  padding: 16px 0 16px 0;
  display: block;
}
.custom-toggle.active .ct-body {
  display: block;
}
.ct-body {
  display: none;
  margin-bottom: 40px;
  padding-left: 5px;
}
.ct-body ul {
  padding-left: 0px;
  list-style: none;
}
.ct-body ul li {
  margin: 5px 0;
}
.ct-body ul li a {
  text-decoration: none;
  position: relative;
}
.ct-body ul li a.active {
  font-weight: bold;
}
.custom-toggle.active:not(.files) .ct-icon svg {
  transform: rotate(90deg);
}
.custom-toggle.active.files .ct-head span {
  font-weight: bold;
}

.nav-link.active span.l {
  width: 100%;
  opacity: 1;
  color: #000;
}

.nav-link span.l {
  position: absolute;
  border-bottom: solid 1px #333;
  bottom: 0px;
  width: 0%;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  left: 0px;
}

.nav-link span.badge {
  min-width: 14px;
  height: 14px;
  border-radius: 14px;
  padding: 3px;
  text-align: center;
  line-height: 8px;
  font-size: 10px;
  background-color: #141414;
  color: #fff;
  position: absolute;
  right: -9px;
  top: 2px;
}
.nav-link span.badge.usernav {
  right: auto;
  left: 10px;
}

.search-item {
  position: relative;
  display: inline-block;
  width: 350px;
  text-decoration: none;
  color: #777;
  /* background-color: #fff;
  border: 1px solid #e1e3e6; */
  border-bottom: 1px solid #000;
}
.search-item:hover {
  text-decoration: none;
  color: #000;
}
.search-item > i {
  position: absolute;
  right: 5px;
  top: 2px;
  color: #000;
}
.search-item button {
  position: absolute;
  padding: 0;
  right: 10px;
  top: 8px;
  color: #000;
  border: none;
  background: none;
}
.search-field {
  outline: none;
  background: transparent;
  border: none;
  /* line-height: 14px; */
  width: 100%;
  padding: 10px 10px;
  padding-right: 35px;
}

.collection-box {
  -webkit-transition: opacity ease 0.3s;
  -o-transition: opacity ease 0.3s;
  transition: opacity ease 0.3s;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 330px;
  color: #fff;
  background-color: #676767;
  text-decoration: none;
  padding: 20px;
  position: relative;
}
.collection-box h2 {
  font-weight: normal;
  letter-spacing: -0.5px;
  margin-bottom: 0px;
}
.collection-box:hover {
  /* opacity: 0.7; */
  color: #fff;
  text-decoration: none;
}
.collection-box h2,
.collection-box div {
  z-index: 2;
}
.collection-box::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  opacity: 0.8;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7035189075630253) 0%, rgba(0, 0, 0, 0) 71%);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.collection-box:hover::after {
  height: 100%;
  opacity: 0.6;
}
.photos-wrap {
  /* padding-right: 70px; */
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}
.photos-wrap .list-item {
  width: 376px;
  flex: 0 0 auto;
  padding-left: 13px;
  padding-right: 13px;
}
.product {
  width: 100%;
  position: relative;
  padding-bottom: 32px;
}
.product .full-link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 2;
}
.thumb.with-border {
  outline: 8px solid #fff;
}

.thumb {
  aspect-ratio: 2 / 3;
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.1);
}
.product:hover .thumb-action {
  display: block;
}
.thumb-action {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  background-color: #00000060;
  width: 100%;
  height: 100%;
}
.product-detail-image:hover .thumb-action {
  display: flex;
}
.product-detail-image .thumb-action {
  height: 50px;
  top: auto;
  bottom: 0;
  /* display: flex; */
}
.product-detail-image .thumb-action .photo-download-btn {
  position: relative;
  display: flex !important;
  width: auto;
  left: auto;
  margin-left: 10px;
  margin-right: 15px;
  text-transform: uppercase;
}
.product-detail-image .folder-dropdown-selected {
  text-transform: uppercase;
  font-size: 14px;
}
.thumb-action-content {
  position: relative;
  z-index: 2;
}

.photo-download-btn {
  left: 15px;
}
.photo-zoom-btn {
  right: 15px;
}
.photo-download-btn,
.photo-zoom-btn {
  position: absolute;
  bottom: 5px;
  height: 40px;
  width: auto;
  z-index: 3;
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
}
.photo-download-btn svg,
.photo-zoom-btn svg {
  opacity: 0.5;
}
.photo-download-btn:hover svg,
.photo-zoom-btn:hover svg {
  opacity: 1;
}

.product .desc {
  text-align: center;
  position: relative;
  margin-top: 20px;
  color: #000;
  /* height: 50px; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  padding: 0 13px;
  padding-right: 17px;
}
.product .desc.auto {
  height: auto;
  overflow: unset;
  display: block;
}
.download-span {
  word-break: keep-all;
  margin-left: 10px;
}
.thumb img {
  width: 100%;
  display: block;
}

.gallery-image {
  margin-bottom: 20px;
  /* display: inline-block; */
}
.gallery-image img {
  width: 100%;
  /* height:  calc(100vh - 180px); */
  /* max-height: calc(100vh - 100px); */
}
.gallery-image figure {
  padding: 0 !important;
  margin: 0 !important;
  /* width: 600px; */
  background-repeat: no-repeat;
}

.gallery-image figure:hover img {
  opacity: 0;
}

.gallery-image img {
  display: block;
  width: 100%;
  pointer-events: none;
}

.product-detail {
  padding-right: 80px;
  margin-bottom: 20px;
}
.product-detail h3.title {
  margin-top: 0px;
  font-size: 30px;
  margin-bottom: var(--mini-gap);
  font-family: "customHelvetica";
}
.product-detail h5.title {
  font-weight: normal;
  position: relative;
  width: 100%;
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 45px;
  opacity: 0.8;
  margin-bottom: 15px;
}
.detail-table {
  border: none;
  border-collapse: collapse;
}
.detail-table td:first-child {
  padding-right: 10px;
  min-width: 100px;
}

/* folder item */
.folder-item {
  margin-bottom: var(--medium-gap);
}
.folder-thumb-wrap {
  position: relative;
  display: block;
}
.folder-thumbs {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.folder-thumb {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #e9e9e9;
}
.folder-all-thumbs {
  display: flex;
  flex-direction: row;
}

.folder-all-thumbs .folder-thumb {
  position: absolute;
  top: 0;
  height: 100%;
  width: 40%;
  border-radius: 25px;
  border: 2px solid #f5f5f5;
}

.folder-all-thumbs .folder-thumb:nth-child(1) {
  left: 0;
  z-index: 5;
}
.folder-all-thumbs .folder-thumb:nth-child(2) {
  left: 20%;
  z-index: 4;
}
.folder-all-thumbs .folder-thumb:nth-child(3) {
  left: 40%;
  z-index: 3;
}
.folder-all-thumbs .folder-thumb:nth-child(4) {
  left: 60%;
  z-index: 2;
}

.folder-grid-thumbs {
  display: flex;
  border-radius: 25px;
  overflow: hidden;
}
.folder-grid-thumbs .folder-thumb {
  /* position: relative; */
  height: 100%;
  border: 1px solid #f5f5f5;
}
.folder-grid-thumbs-col1 {
  width: 60%;
  position: relative;
}
.folder-grid-thumbs-col2 {
  width: 40%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.folder-name {
  padding-top: var(--mini-gap);
  padding-left: var(--small-gap);
  font-weight: bold;
  font-size: 16px;
}
.folder-action {
  visibility: hidden;
  /* padding-top: var(--mini-gap);
  padding-right: var(--small-gap); */
  /* display: flex;
 justify-content: center; */
}
.folder-item:hover .folder-action {
  visibility: visible;
}

.folder-dropdown {
  font-size: 16px;
}
.folder-dropdown-selected {
  color: #fff;
  padding: 5px;
  display: flex;
  align-items: center;
}
.folder-dropdown-selected svg {
  margin-left: 10px;
}
.folder-dropdown-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.folder-dropdown-item:hover .btn {
  visibility: visible;
}
.folder-dropdown-item .btn {
  visibility: hidden;
}

/* news item */
.news-item {
  display: block;
  width: 100%;
  margin-bottom: 30px;
  text-align: center;
}
.news-thumb-wrap {
  position: relative;
  display: block;
}
.news-thumb {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #d3ced2;
}
.news-link-title {
  text-align: center;
  color: #333;
  position: relative;
  width: auto;
  display: inline-block;
  margin-top: 15px;
  text-decoration: none;
}
.news-link-title span.l {
  position: absolute;
  border-bottom: solid 1px #333;
  bottom: -3px;
  width: 0%;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  left: 0px;
}
.news-item:hover span.l {
  width: 100%;
  opacity: 1;
}
/* news detail */
.news-wrap {
  max-width: 730px;
  margin: auto;
}
.news-title {
  text-align: center;
  font-size: 28px;
}
.news-content {
}
.news-content h2 {
  font-size: 28px;
}
.news-content h3 {
  font-size: 24px;
}
.news-content figure {
  margin: 0;
}
.news-content figcaption {
  text-align: center;
  font-size: 13px;
  font-style: italic;
}
.news-content blockquote {
  margin-top: 40px;
  background-color: #eeeeee;
  margin: 35px 0px;
  border-radius: 10px;
  /* margin-top: 35px;
  margin-bottom: 35px; */
  padding: 20px 40px;
  padding-bottom: 23px;
  /* margin-left: 0; */
  /* padding-left: 40px; */
}
.news-content .divider {
  border: none;
  text-align: center;
}
.news-content .divider::before {
  display: inline-block;
  content: "***";
  font-size: 30px;
  line-height: 65px;
  height: 54px;
  letter-spacing: 0.2em;
}
.dropdown {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.dropdown-content {
  position: absolute;
  overflow: hidden;
  z-index: 55;
  left: 0;
  background-color: var(--bg-color-white);
  /* border: 1px solid var(--border-color-gray); */
  border-radius: 5px;

  box-shadow: 0 2px 8px rgb(0 0 0 / 10%);

  transform: translate3d(0, 0, 0);
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out, width 0.2s ease-in-out, height 0.2s ease-in-out;
  top: calc(100% - 1px);
  width: 330px;
  min-width: 150px;
  padding: 10px 4px;
  max-height: 520px;
  overflow-y: auto;
}

.dropdown-content.medium {
  width: 300px;
  padding: 25px;
}
.dropdown.right .dropdown-content {
  right: 0 !important;
  left: auto;
}
.dropdown-content.small {
  width: 200px;
}

.dropdown-listitem {
  position: relative;
  color: var(--text-color-dark);
}

.dropdown-listitem > div,
.dropdown-listitem > a {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  color: var(--text-color-dark);
  font-size: 0.9375rem;
}
.dropdown-listitem > a {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.6;
}
.dropdown-listitem:hover a {
  background-color: var(--bg-color-gray);
  color: var(--text-color-dark);
}
.dropdown-listitem span.badge {
  min-width: 14px;
  height: 14px;
  border-radius: 3px;
  margin-left: 4px;
  padding: 3px;
  text-align: center;
  line-height: 8px;
  font-size: 10px;
  background-color: #141414;
  color: #fff;
}
.dropdown-divider {
  display: block;
  margin-left: -4px;
  margin-right: -4px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.dropdown-divider hr {
  margin: 0;
}
.dropdown-label {
  font-size: 0.75rem;
  color: var(--text-color-secondary);
}
.dropdown-label,
.dropdown-listitem > div,
.dropdown-listitem > a,
.dropdown-divider {
  padding: 9px 17px;
}
.menu-dropdown .dropdown-listitem > a {
  font-size: 14px;
}

.filter-dropdown .dropdown-listitem > a {
  font-size: 14px;
}
.filter-selected-item {
  /* text-decoration: underline; */
  font-size: 14px;
  color: #676767;
  display: inline-block;
  margin-right: 15px;
}
/* modal */
.modal-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  background-color: var(--bg-color-darkgray);
  opacity: 0.8;
}
.modal-backdrop.locked {
  cursor: not-allowed;
}
.modal.dark .modal-backdrop {
  background-color: var(--bg-color-secondary);
}
.modal.dark .modal-dialog {
  -webkit-box-shadow: 0px 1px 10px 0px var(--bg-color-secondary);
  -moz-box-shadow: 0px 1px 10px 0px var(--bg-color-secondary);
  box-shadow: 0px 1px 10px 0px var(--bg-color-secondary);
}
.modal-root {
  z-index: 101;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 105;
  /* display: none; */
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-dialog {
  outline: none;
  min-width: 410px;
  max-width: 410px;
  z-index: 108;
  position: relative;
  top: 0%;
  box-sizing: border-box;
  /* max-height: 873px; */

  /* initial */
  z-index: 3000;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: var(--radius-medium);
  background-color: var(--bg-color-white);

  /* margin-top: 120px; */
  margin-left: auto;
  margin-right: auto;
  overflow: initial;
  /* height: 620px; */
  /* min-height: 188px; */
  color: var(--color-core);
  -webkit-box-shadow: 0px 1px 10px 0px var(--bg-color-darkgray);
  -moz-box-shadow: 0px 1px 10px 0px var(--bg-color-darkgray);
  box-shadow: 0px 1px 10px 0px var(--bg-color-darkgray);
}

.modal-dialog {
  min-width: 500px;
  max-width: 500px;
}
.modal-dialog.medium {
  min-width: 620px;
  max-width: 620px;
}

.modal-content {
  position: relative;
}
.modal-body {
  padding: 50px 60px;
}
/* orders, requests */
.current-inorders-wrap {
  background-color: var(--bg-color-white);
  /* background-color: #ebebeb; */
  /* border: 1px solid #212529; */
}

.current-inorders-top,
.current-inorders-bottom {
  padding: 30px 22px;
}
.current-inorders-top {
  border-bottom: 1px solid #e2e2e2;
}
.inorders-item-wrap {
  display: flex;
  align-items: center;
}

.order-thumb {
  display: inline-block;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: var(--mini-gap);
  background-color: #e9e9e9;
}
.order-thumb.small {
  width: 60px;
}
.order-thumb img {
  width: 100%;
  display: block;
}

.prev-order-wrap {
  padding-left: 22px;
  padding-right: 22px;
}

.orders-list-wrap {
}
.orders-list:first-child {
  border-top: 1px solid #ebebeb;
}
.orders-list {
  padding: 30px 0;
  border-bottom: 1px solid #ebebeb;
}

.order-info {
  background-color: #ebebeb;
  padding: 22px;
}

.product-list-table {
}
.product-list-table-head {
  color: var(--text-color-secondary);
  margin-bottom: var(--small-gap);
}
.product-list-table-head,
.product-list-table-body-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.product-list-table-body-row:first-child {
  border-top: 1px solid #ebebeb;
}
.product-list-table-body-row {
  position: relative;
  padding: 15px 0;
  border-bottom: 1px solid #ebebeb;
}
.product-list-table-body-row:last-child {
  border-bottom: none;
}

.product-list-table-name {
  width: 300px;
}
.product-list-table-code {
  width: 100px;
}
.product-list-table-material {
  width: 140px;
}
.product-list-table-color {
  width: 210px;
}
.product-list-table-quantity {
  width: 250px;
}
.product-list-table-comment {
  /* flex-basis: 100%; */
  width: 200px;
}
.product-list-table-action {
  width: 125px;
}

.product-list-table-body-row:hover .product-list-table-closer {
  visibility: visible;
}
.product-list-table-closer {
  cursor: pointer;
  visibility: hidden;
  padding: 10px;
}

.product-list-quantity {
  display: flex;
}
.product-list-quantity-item {
  margin-right: var(--mini-gap);
}
.product-list-quantity-item-name {
  text-transform: uppercase;
  text-align: center;
}
.product-list-quantity-item-control {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.product-list-quantity-item-input {
  width: 30px;
  height: 30px;
  text-align: center;
}

.product-list-quantity-item-input.number::-webkit-outer-spin-button,
.product-list-quantity-item-input.number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.product-list-quantity-item-input.number {
  -moz-appearance: textfield;
}
.product-list-quantity-item-btn {
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  user-select: none;
}
.product-list-quantity-item-btn:hover {
  background-color: #e1e1e1;
}

.two-line-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.login-form-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-form-wrap form {
  width: 350px;
  border: 1px solid #d3ced2;
  padding: 20px;
  margin: 2em 0;
  text-align: left;
  border-radius: 5px;
  overflow: hidden;
}

.form-group {
  margin-bottom: 1rem;
}
.form-group label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.form-control {
  border: 1px solid #e2e2e2;
  border-radius: 0;
  box-shadow: none;
  padding: 20px 14px;
  color: #333;
  font-weight: normal;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);

  font-size: 1rem;

  background-color: #fff;

  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control.disabled {
  opacity: 0.6;
}
textarea.form-control {
  min-height: 200px;
  max-width: 100%;
}
.form-control:focus {
  border: 1px solid #000;
  outline: none;
}

.attr-list-group {
  border: 1px solid #ebebeb;
  max-height: 400px;
  overflow-y: auto;
}
.attr-list-item:last-child {
  border: none;
}
.attr-list-item {
  padding: 10px;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.attr-list-item.active,
.attr-list-item:hover {
  background-color: #f5f5f5;
}
.attr-list-item.choosed {
  height: 48px;
  border: 1px solid #ebebeb !important;
  padding: 5px;
  padding-right: 10px;
  text-decoration: none !important;
}

.attr-list-item.choosed .attr-name {
  /* max-width: 80px; */
  text-align: left;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.attr-thumb {
  width: 36px;
  height: 36px;
  background-color: #ebebeb;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* loaders */
.list-loader-wrap {
  height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinner-border-wrap {
  z-index: 55;
}
.btn .spinner-border-wrap {
  width: auto !important;
  margin-right: 10px;
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid #212529;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.spinner-border.light {
  border: 0.25em solid #e4e4e4;
  border-right-color: transparent;
}
.spinner-border.small {
  border-width: 0.12em;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
/* skeleton loader navigation */
.sk-animate-loader {
  animation-duration: 2.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.sk-animate-loader.overlay-navitem {
  animation-name: overlay-navitem;
}
.sk-animate-loader.overlay-post {
  animation-name: overlay-post;
}

@keyframes overlay-post {
  0% {
    transform: translateX(-786px);
    opacity: 0;
  }
  50% {
    transform: translateX(786px);
    opacity: 0.4;
  }
  100% {
    transform: translateX(786px);
    opacity: 0.4;
  }
}

.sk-loader linearGradient stop {
  stop-color: #f5f5f5;
  /* stop-color: var(--bg-color-white); */
}
.sk-loader g rect,
.sk-loader g circle {
  fill: #f1f1f1;
}
.fpost-sk-loader g rect,
.fpost-sk-loader g circle {
  /* fill: var(--loader-sk-fpfill); */
  fill: #f1f1f1;
}
.sk-loader-navigation linearGradient stop {
  stop-color: var(--bg-color-white);
}
.sk-loader-navigation g rect,
.sk-loader-navigation g circle {
  fill: #f1f1f1;
}
.sk-loader-trendblog g rect,
.sk-loader-trendblog g circle {
  fill: #f1f1f1;
}
.nofill {
  fill: transparent !important;
}
.sk-loader g rect.fillwhite,
.sk-loader g circle.fillwhite {
  fill: var(--bg-color-white);
}
/* end loaders */

.filter-item {
  display: inline-block;
  padding: 10px 0;
  margin-right: 35px;
}
.filter-item svg {
  margin-left: 10px;
}

.overflow-hidden {
  overflow: hidden;
}

.term-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #00000060;
  z-index: 999;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
}
.term-bg {
  max-width: 1100px;
  width: 100%;
  height: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fff;
}
.term-content {
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  position: relative;
}


.price-area{
  font-size: 16px;
  font-weight: bold;
}
.has-sale{
  font-size: 14px;
  color: #676767;
  text-decoration-line: line-through; 
}
/* `xxxl` applies to x-large devices (large desktops, less than 1400px) */
@media (max-width: 1599.98px) {
  html {
    /* --side-width: 320px; */
    --side-width: 275px;
  }
  .photos-wrap .list-item {
    /* width: 33.333%; */
  }
}

/* `xxl` applies to x-large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
  html {
    --side-width: 280px;
  }
}
/* `xl` applies to large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .photos-wrap .list-item {
    /* width: 50%; */
  }
  .product-detail {
    padding-right: 0;
  }
}
/* `lg` applies to medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}
/* `md` applies to small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}
/* `sm` applies to x-small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}
